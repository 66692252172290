<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <ayl-table :table="table">
          <div slot="ctrl-button">
           <el-button type="warning" @click="$router.push('/operation/internal-officer/add-internal-officer')">新增</el-button>
          </div>
        </ayl-table>
      </div>
    </div>
    <el-dialog
      title="身份证"
      :visible.sync="idCardDialogVisible"
      width="1000px"
      center>
      <div class="content-main">
          <div class="order-img" :style="{'margin-right': (idx+1)%3===0?'0px':''}"
              v-for="(item, idx) in idCardImageUrls" :key="idx">
            <el-image
                    :preview-src-list="imgSrcList"
                    :src="item"
                    @click="onPreview(idx,idCardImageUrls)"
                    fit="cover" style="width: 100%;height: 100%;border-radius: 4px"></el-image>
            <!-- <i class="el-icon-error close-icon" v-if="item.isClear" @click="clickClearImg(idx)"></i> -->
          </div>
        </div>
    </el-dialog>

    <el-dialog
    title="驾驶证"
    :visible.sync="drivingLicenseDialogVisible"
    width="1000px"
    center>
    <div class="content-main">
      <div class="order-img" :style="{'margin-right': (idx+1)%3===0?'0px':''}"
          v-for="(item, idx) in drivingLicenseImageUrlData" :key="idx">
        <el-image
                :preview-src-list="imgSrcList"
                :src="item"
                @click="onPreview(idx,drivingLicenseImageUrlData)"
                fit="cover" style="width: 100%;height: 100%;border-radius: 4px"></el-image>
        <!-- <i class="el-icon-error close-icon" v-if="item.isClear" @click="clickClearImg(idx)"></i> -->
      </div>
    </div>
    </el-dialog>

    <el-dialog
    title="从业资格证"
    :visible.sync="transportLicenseDialogVisible"
    width="1000px"
    center>
    <div class="content-main">
      <div class="order-img" :style="{'margin-right': (idx+1)%3===0?'0px':''}"
          v-for="(item, idx) in transportLicenseUrlData" :key="idx">
        <el-image
                :preview-src-list="imgSrcList"
                :src="item"
                 @click="onPreview(idx,transportLicenseUrlData)"
                fit="cover" style="width: 100%;height: 100%;border-radius: 4px"></el-image>
        <!-- <i class="el-icon-error close-icon" v-if="item.isClear" @click="clickClearImg(idx)"></i> -->
      </div>
    </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data () {
      const vm = this
      return {
        imgSrcList: [],

        idCardDialogVisible: false,
        drivingLicenseDialogVisible: false,
        transportLicenseDialogVisible: false,
        drivingLicenseImageUrlData: [],      //驾驶证
        idCardImageUrls: [],             //身份证
        transportLicenseUrlData: [],   //从业资格证
        nav: [{name: "驾驶员管理", path: ""}, {name: "内部驾驶员维护", path: ""}],
        table: {
          api: vm.$api.driverList,
          query: {
            type: 0,
            queryContent: null,
          },
          // 是否显示分页
          // hidePagination: true,
          // 表格查询的条件组件
          searchData: [
            {
              type: 'input',
              model: 'queryContent',
              placeholder: '姓名/手机号码'
            }],
          columns: [
            //   {
            //   title: '序号',
            //   width: '50px',
            //   $index: 'index',
            // },
            {
              title: '姓名',
              width: '100px',
              key: 'driverName',
            }, {
              title: '手机号码',
              width: '120px',
              key: 'contactPhone',
            }, {
              title: '性别',
              width: '100px',
              key: 'sex',
            }, {
              title: '身份证号',
              key: 'idCardNumber',
              showTooltip: true,
            }, {
              title: '驾驶证有效期',
              key: 'licenseTime',
              showTooltip: true,
            }, {
              title: '身份证',
              width: '90px',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view': ctx.row.idCardImgNum=== 0 ? false : true,
                    },
                    on: {
                      click: ctx.row.idCardImgNum === 0 ? vm.onNull.bind(this, ctx.row) : vm.idCardData.bind(this, ctx.row)
                    }
                  }, ctx.row.idCardImgNum)
                ])
              },
            }, {
              title: '驾驶证',
              width: '90px',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view': ctx.row.drivingLicenseImgNum=== 0 ? false : true,
                    },
                    on: {
                      click: ctx.row.drivingLicenseImgNum === 0 ? vm.onNull.bind(this, ctx.row) : vm.drivingLicenseData.bind(this, ctx.row)
                    }
                  }, ctx.row.drivingLicenseImgNum)
                ])
              },
            }, {
              title: '从业资格证',
              width: '90px',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view': ctx.row.transportLicenseImgNum=== 0 ? false : true,
                    },
                    on: {
                      click: ctx.row.transportLicenseImgNum === 0 ? vm.onNull.bind(this, ctx.row) : vm.transportLicenseData.bind(this, ctx.row)
                    }
                  }, ctx.row.transportLicenseImgNum)
                ])
              },
            }, {
              title: '操作',
              width: '105px',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view': true,
                    },
                    on: {
                      click: vm.goEdit.bind(this, ctx.row)
                    }
                  }, '编辑'),
                  // h('span', {
                  //   class: {
                  //     'table-delete': true
                  //   },
                  //   on: {
                  //     click: vm.delete.bind(this, ctx.row)
                  //   }
                  // }, '删除')
                ])
              }
            }]
        }
      }
    },
    methods: {
      // 依次查看大图
      onPreview(index,item) {
        let arr1 = item.filter((arrItem, arrIndex) => index <= arrIndex);
        let arr2 = item.filter((arrItem, arrIndex) => index > arrIndex);
        let arr3 = arr2.sort((a, b) => {
          b - a;
        });
        this.imgSrcList = [...arr1, ...arr3];
      },

      
      //删除
      async delete(val){
        await this.$confirm(`您确认删除【${val.driverName}】吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        await this.$api.driverDelete({
          driverId: val.driverId
        })
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        })
        await this.$search(this.table)
      },
      //编辑
      goEdit(val){
        this.$router.push({
          path: '/operation/internal-officer/edit-internal-officer',
          query: {
            driverId: val.driverId,
          }
        })
      },
      onNull(){},
      //身份证
      async idCardData(val) {
          this.idCardDialogVisible = true
          this.idCardImageUrls = val.idCardImageUrls
          // await this.$nextTick(() => {
          //   this.$search(this.idCardImageUrls)
          // });
      },

      //驾驶证
      async drivingLicenseData(val) {
          this.drivingLicenseDialogVisible = true
          this.drivingLicenseImageUrlData = []
          if(val.drivingLicenseImageUrl){
            this.drivingLicenseImageUrlData.push(val.drivingLicenseImageUrl)
          }
          if(val.drivingLicenseImageUrl1){
            this.drivingLicenseImageUrlData.push(val.drivingLicenseImageUrl1)
          }
      },
      
      //从业资格证
      async transportLicenseData(val) {
          this.transportLicenseDialogVisible = true
          this.transportLicenseUrlData = []
          if(val.transportLicenseImageUrl){
            this.transportLicenseUrlData.push(val.transportLicenseImageUrl)
          }
          if(val.transportLicenseImageUrl1){
            this.transportLicenseUrlData.push(val.transportLicenseImageUrl1)
          }
          // await this.$nextTick(() => {
          //   this.$search(this.drivingLicenseImageUrlData)
          // }); 
      },
    },
    beforeRouteEnter (to, from, next) {
      if(to.name=="Internal-officer"){
        next(async vm=>{
          await vm.$search(vm.table)
        });
      }else{  
        next();
      }
    },
    // async mounted () {
    //   await this.$search(this.table)
    // }
  }
</script>

<style lang='sass' scoped>
.avatar-uploader .el-upload 
    border: 1px dashed #d9d9d9
    border-radius: 6px
    cursor: pointer
    position: relative
    overflow: hidden
    vertical-align: top

/deep/ .el-upload
    vertical-align: top

.avatar-uploader .el-upload:hover
    border-color: #409EFF

.avatar-uploader-icon 
    font-size: 28px
    color: #8c939d
    width: 300px
    height: 150px
    line-height: 150px
    text-align: center
  
.avatar 
    width: 300px
    height: 150px
    display: block
    border: 1px solid rgba(217,217,217,1)
    border-radius: 4px
    background: rgba(250,250,250,1)
    position: relative
    z-index: 0
    margin-right: 20px

.avatar-item
    height: 70px
    position: absolute
    z-index: 1
    top: 38px
    left: 102px

.avatar-item-license 
    height: 74px
    position: absolute
    z-index: 1
    top: 38px
    left: 114px

.avatar-icon 
    width: 48px
    height: 48px
    display: table-cell
    margin: 0 auto

.avatar-text 
    text-align: center
    font-size: 12px
    font-family: Microsoft YaHei
    font-weight: 400
    color: rgba(191,191,191,1)

.avatar-img 
    width: 300px
    height: 150px

.order-img
    position: relative
    width: calc((100% - 2 * 13px) / 3)
    height: 138px
    display: inline-block
    margin-right: 13px
    margin-bottom: 14px
    border-radius: 4px
.close-icon
  position: absolute
  top: -5px
  right: -8px
  font-size: 14px
  cursor: pointer

/deep/ .el-image-viewer__close
  color: #fff
/deep/ .el-image-viewer__next,
/deep/ .el-image-viewer__prev
  border: 3px solid #fff
</style>
